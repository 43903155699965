<template>
  <div class="material-type">
    <material-nav @search="handleSearch" @add="handleAddItem"></material-nav>
    <list :list="list" @edit="handleEditItem"></list>
    <edit-type
      :show="show"
      :type="type"
      :item="item"
      @succ="handleSucc"
    ></edit-type>
  </div>
</template>

<script>
import List from "./components/List";
import MaterialNav from "./components/Nav";
import EditType from "./components/EditType";
export default {
  name: "MaterialType",
  components: { MaterialNav, List, EditType },
  data() {
    return {
      show: false,
      type: "add",
      list: [],
      item: {},
    };
  },
  created() {
    this.getMaterialType();
  },
  methods: {
    //获取原材料分类
    async getMaterialType() {
      const res = await this.$store.dispatch("getMaterialType");
      this.list = res.materialCategories;
      this.allList = this.list;
    },
    //搜索
    handleSearch(val) {
      if (val === "") {
        this.list = this.allList;
        return;
      }
      this.list = this.allList.reduce((prev, cur) => {
        if (cur.name.indexOf(val) > -1) {
          prev.push(cur);
        }
        return prev;
      }, []);
    },
    //点击弹出新增弹窗
    handleAddItem() {
      this.show = !this.show;
      this.type = "add";
    },
    handleEditItem() {},
    //提交成功
    handleSucc() {
      this.getMaterialType();
    },
  },
};
</script>
<style lang="less" scoped>
</style>