<template>
  <table-container :list="list">
    <el-table-column prop="sort" label="排序" width="80"> </el-table-column>
    <el-table-column prop="name" label="名称"> </el-table-column>
    <el-table-column prop="model_id" label="模型"> </el-table-column>
    <el-table-column prop="created_at" label="创建日期"> </el-table-column>
    <el-table-column prop="updated_at" label="修改日期"> </el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        <el-button
          type="text"
          icon="el-icon-edit"
          size="mini"
          @click="handleEdit(scope.row)"
          >编辑</el-button
        >
      </template>
    </el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
  },
  methods: {
    //编辑
    handleEdit(item) {
      this.$emit("edit", item);
    },
  },
};
</script>
<style lang="less" scoped>
</style>