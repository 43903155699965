<template>
  <el-row :gutter="20">
    <el-col :span="4">
      <el-input
        placeholder="请输入分类关键字"
        v-model="searchVal"
        class="input-with-select"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleSearch"
        ></el-button>
      </el-input>
    </el-col>
    <el-col :span="2">
      <el-button type="primary" icon="el-icon-plus" @click="handleAddItem"
        >新增原材料类型</el-button
      >
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: "Nav",
  data() {
    return {
      searchVal: "",
    };
  },
  methods: {
    handleAddItem() {
      this.$emit("add");
    },
    //搜索
    handleSearch() {
      this.$emit("search", this.searchVal);
    },
  },
};
</script>
<style lang="less" scoped>
</style>