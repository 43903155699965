<template>
  <ky-dialog :title="title" :show="show" :hide="hide">
    <el-form
      slot="dialog-content"
      :label-position="labelPosition"
      label-width="90px"
      :model="fromData"
      :rules="rules"
      ref="fromData"
    >
      <el-form-item label="类型名称:" prop="name">
        <el-input v-model="fromData.name"></el-input>
      </el-form-item>
      <el-form-item label="产品模型:" prop="model_id">
        <el-select
          v-model="fromData.model_id"
          style="display: block"
          placeholder="请选择"
        >
          <el-option
            v-for="item in modelList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序:">
        <el-input v-model.number="fromData.sort"></el-input>
      </el-form-item>
    </el-form>
    <span slot="dialog-footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleComfirm">确 定</el-button>
    </span>
  </ky-dialog>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "EditType",
  props: {
    type: String,
    show: Boolean,
    item: Object,
  },
  data() {
    return {
      hide: false,
      title: "新增原材料类型",
      labelPosition: "right",
      fromData: {
        id: "",
        model_id: "",
        name: "",
        sort: 1,
      },
      rules: {
        name: [
          { required: true, message: "请输入原材料分类名称", trigger: "blur" },
        ],
        model_id: [
          {
            type: "number",
            required: true,
            message: "不能为空并且必须为数字",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(["modelList"]),
  },
  watch: {
    show(e) {
      if (this.type === "add") {
        this.title = "新增原材料类型";
        this.fromData = {
          id: "",
          model_id: "",
          name: "",
          sort: 1,
        };
        this.imageSrc = "";
      } else {
        this.title = "编辑原材料类型";
        this.fromData = {
          id: this.item.id,
          model_id: this.item.model_id,
          name: this.item.name,
          sort: this.item.sort,
        };
      }
    },
  },
  created() {
    this.getGoodsModels();
  },
  methods: {
    async getGoodsModels() {
      await this.$store.dispatch("getGoodsModels");
    },
    //取消
    handleCancel() {
      this.hide = !this.hide;
    },
    //确定
    handleComfirm() {
      let fromData = new FormData();
      fromData.append("name", this.fromData.name);
      fromData.append("model_id", this.fromData.model_id);
      fromData.append("sort", this.fromData.sort);
      if (this.type === "add") {
        this.handleAddComfirm(fromData);
        return;
      } else {
        fromData.append("id", this.fromData.id);
        this.handleEditComfirm(fromData);
      }
    },
    //新增提交
    async handleAddComfirm(fromData) {
      const res = await this.$store.dispatch("addMaterialType", fromData);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
    //编辑提交
    async handleEditComfirm(fromData) {
      const res = await this.$store.dispatch("editType", fromData);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>